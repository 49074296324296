.w-full {
  width: 100% !important;
}

.ml-0 {
  margin-left: 0px !important;
}

.ml-12 {
  margin-left: 12px !important;
}

.mt-16 {
  margin-top: 16px !important;
}

.mb-16 {
  margin-bottom: 16px !important;
}

.w-100 {
  width: 100% !important;
}
.w-70 {
  width: 70% !important;
}
//.date-picker-order {
//  .k-picker-wrap {
//    border: none !important
//  }
//}
.justify-content-between {
  justify-content: space-between;
  -webkit-justify-content: space-between;
}
.dropzone {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  // padding: 20px;
  width: 600px;
  border: 2px dashed #0087F7;
  border-radius: 2px;
  background-color: #fafafa;
  color: #bdbdbd;
  outline: none;
  transition: border .24s ease-in-out;
}

.dropzone-container {
  display: flex;
  flex-direction: column;
  font-family: sans-serif;
  cursor: pointer;
}

.file-link {
  color: blue;
}

.file-link:hover {
  cursor: pointer;
  text-decoration: underline;
}


.gallery-image {
  border-radius: 5px;
  cursor: pointer;
  transition: 0.3s;
}

.gallery-image:hover {opacity: 0.7;}

/* The Modal (background) */
.modal {
  display: none; /* Hidden by default */
  position: fixed; /* Stay in place */
  z-index: 9999; /* Sit on top */
  padding-top: 100px; /* Location of the box */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0,0,0); /* Fallback color */
  background-color: rgba(0,0,0,0.9); /* Black w/ opacity */
}

/* Modal Content (image) */
.modal-content {
  margin: auto;
  display: block;
  width: 80%;
  max-width: 700px;
}

/* Add Animation */
.modal-content {
  -webkit-animation-name: zoom;
  -webkit-animation-duration: 0.6s;
  animation-name: zoom;
  animation-duration: 0.6s;
}

@-webkit-keyframes zoom {
  from {-webkit-transform:scale(0)}
  to {-webkit-transform:scale(1)}
}

@keyframes zoom {
  from {transform:scale(0)}
  to {transform:scale(1)}
}

/* The Close Button */
.close {
  position: absolute;
  top: 0;
  right: 35px;
  color: #f1f1f1;
  font-size: 40px;
  font-weight: bold;
  transition: 0.3s;
}

.close:hover,
.close:focus {
  color: #bbb;
  text-decoration: none;
  cursor: pointer;
}

/* 100% Image Width on Smaller Screens */
@media only screen and (max-width: 700px){
  .modal-content {
    width: 100%;
  }
}

.pd-5 {
  padding: 5px !important;
}

.red-color {
  color: red !important
}

.margin-left-187cm {
  margin-left: 1.87cm;
}

.order_invoice_totals{
  display: flex;
  justify-content: space-between;
  flex-direction: column;

}
.order_invoice_totals p{
  margin:  0;
}
.order_invoice_totals_box{
  align-self: flex-end;
  width: 300px;
}
