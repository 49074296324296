// Colors
$primary-color: #1d283e;
$secondary-color: #56e39f;
$gray-border: #eaeef1;
$error-color: #f96161;

// Forms
$input-color: #000;
$input-bg: #fff;
$input-border: #ccc;
$focus-color: #79b8f5;
$border-radius: 3px;
$unit: 8px;

// Header
$header-height: 60px;

// Spacing
$wrapper-horiz-pad: 10px;
