@mixin font-face($font-family, $size, $weight: null) {
  font-family: $font-family;
  font-size: $size;
  font-weight: $weight;
}

@mixin breakpoint($point) {
  @if $point == small {
    @media (max-width: 30em) {
      @content;
    }
  } @else if $point == medium {
    @media (min-width: 30em) and (max-width: 50em) {
      @content;
    }
  } @else if $point == large {
    @media (min-width: 50em) and (max-width: 68.75em) {
      @content;
    }
  }
}

@mixin clearfix() {
  &::after {
    content: "";
    display: table;
    clear: both;
  }
}
