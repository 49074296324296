// Animations
@keyframes spinner {
  to {
    transform: rotate(360deg);
  }
}

.is- {
  &hidden {
    display: none;
  }

  &loading {
    display: block;
    position: relative;

    > *:not(h1):not(h2):not(h3):not(h4):not(h5):not(h6) {
      visibility: hidden;
    }

    &::before {
      content: "";
      box-sizing: border-box;
      position: absolute;
      top: 50%;
      left: 50%;
      width: 30px;
      height: 30px;
      margin-top: -15px;
      margin-left: -15px;
      border-radius: 50%;
      border: 3px solid #ccc;
      border-top-color: $primary-color;
      animation: spinner 0.6s linear infinite;
    }
  }
}
