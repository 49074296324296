.language-selectbox {
  position: relative;
  flex-grow: 1;

  .flag {
    width: 20px;
    height: 20px;
    float: right;
    cursor: pointer;
  }

  .language-menu {
    position: absolute;
    right: 0;
    top: 8px;
    border-radius: 5px;
    color: rgba(0, 0, 0, 0.87);
    padding: 5px 15px;
    background-color: #f3ebeb;

    .language-item {
      display: flex;
      align-items: center;
      padding: 5px;
      cursor: pointer;

      &:hover {
        color: chocolate;
      }

      .flag {
        float: left;
        margin-right: 5px;
      }
    }
  }
}
