.doc-annex .container {
    width: 1170px;
  }

  .contract_doc .container {
    padding: 0 10px;
  }

  .contract_doc .row-margin {
      margin-bottom: 1.5rem;
  }

  .contract_doc .row {
      display: flex;
      flex-wrap: wrap;
  }

  .contract_doc .column-6 {
      float: left;
      width: 50%;
  }
 
  .contract_doc .column-6 p {
      font-size: 14px;  
  }
  .contract_doc .column-3 {
    float: left;
    width: 25%;
}

.contract_doc .column-9 {
    float: left;
    width: 75%;
}

.contract_doc .p-margin {
    margin: 0;
}
.contract_doc .col-margin {
    margin-bottom: 1rem;
}

.contract_doc .column-12 {
    float: left;
    width: 100%;
}
.contract_doc .column-2 {
    float: left;
    width: 16.666667%;
}

.contract_doc .column-6 {
    float: left;
    width: 50%;
}
.contract_doc .padding-2 {
    padding-bottom: .8rem;
}

.contract_doc .padding-3 {
    padding-bottom: 1rem;
}

.contract_doc .padding-4 {
    padding-bottom: 1.5rem;
}

.contract_doc .bottom-border2 {
    border-bottom: 2px solid #000;
    width: 65%;
}

.contract_doc .bottom-border3 {
    border-bottom: 2px solid #000;
    width: 18%;
}

.contract_doc .bottom-border4 {
    border-bottom: 2px solid #000;
    width: 40%;
}

.contract_doc .bottom-border5 {
    border-bottom: 2px solid #000;
    width: 42%;
}
.contract_doc  .column-10 {
    float: left;
    width: 83.333333%;
}
.contract_doc .column-4 {
    float: left;
    width: 33.333333%;
}
.contract_doc .col-align {
    text-align: center;
}
.contract_doc  .col-margin {
    margin-bottom: 1rem;
}
.contract_doc  .column-7 {
    float: left;
    width: 58.333333%;
}
.contract_doc .bottom-border {
    border-bottom: 2px solid #000;
}
.contract_doc .align-self-end {
    align-self: flex-end!important;
}