/***** General CSS *****/
.doc-annex {
  body {
       word-break: break-word;
       font: 15px/25px 'Arial';
       color: #393939;
       overflow-x: hidden;
     }

  a {
    text-decoration: none;
    color: #28b16d;
    white-space: initial;
  }

  a:hover,
  a:focus {
    text-decoration: none;
    color: #393939;
  }

  a:hover {
    transition: all 0.5s ease-in-out;
    -webkit-transition: all 0.5s ease-in-out;
    -moz-transition: all 0.5s ease-in-out;
  }

  img {
    max-width: 100%;
  }

  input[type='text']:focus,
  textarea:focus,
  input[type='password']:focus,
  select:focus,
  input[type='email']:focus,
  input[type='url']:focus,
  input[type='tel']:focus,
  input[type='number']:focus {
    transition: all 0.5s ease;
    -webkit-transition: all 0.5s ease;
    -moz-transition: all 0.5s ease;
    outline: none;
  }

  select,
  input[type='text'],
  input[type='password'],
  input[type='email'],
  input[type='url'],
  input[type='number'],
  textarea,
  input[type='tel'] {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
  }

  ul {
    margin: 0 0 20px;
    padding: 0;
    list-style-type: none;
  }

  p {
    font-weight: 500;
  }

  /***** Custom Classes *****/
  .noPadding {
    padding: 0;
  }

  .noLeft {
    padding-left: 0;
  }

  .noRight {
    padding-right: 0;
  }

  .centerCol {
    float: none;
    margin: 0 auto;
  }

  .btn {
    font-size: 14px;
    line-height: 20px;
    font-weight: 500;
    padding: 15px 40px;
    color: #fff;
    margin: 20px 20px 20px 0;
    border-radius: 50px;
    -webkit-border-radius: 50px;
    -moz-border-radius: 50px;
    text-transform: capitalize;
    border: 2px solid;
    text-transform: uppercase;
  }

  .btnGreen {
    background-color: #28b16d;
    color: #fff;
    border-color: #28b16d;
  }

  .btnGreen:focus,
  .btnGreen:hover {
    background-color: #fff;
    color: #28b16d;
    border-color: #28b16d;
  }

  img.pull-left,
  .imgOverlay.pull-left {
    margin: 0 30px 20px 0;
  }

  img.pull-right {
    margin: 0 0 20px 30px;
  }

  .flexRow {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    align-items: center;
  }

  .flexCol {
    -webkit-box-flex: 1;
    -moz-box-flex: 1;
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
    align-items: center;
  }

  h1 {
    font-family: 'Arial';
    font-size: 55px;
    line-height: 60px;
    color: #fff;
    font-weight: 500;
    margin: 0 0 17px;
  }

  h2 {
    font-family: 'Arial';
    font-size: 55px;
    line-height: 60px;
    color: #393939;
    font-weight: 500;
    margin: 0 0 30px;
  }

  h3 {
    font-family: 'Arial';
    font-size: 30px;
    line-height: 34px;
    color: #000000;
    font-weight: 600;
    margin: 0 0 28px;
  }

  h4 {
    font-family: 'Arial';
    font-size: 24px;
    line-height: 29px;
    color: #393939;
    font-weight: 500;
    margin: 0 0 13px;
  }

  h5 {
    font-family: 'Arial';
    font-size: 20px;
    line-height: 25px;
    color: #393939;
    font-weight: 500;
    margin: 0 0 20px;
  }

  h6 {
    font-family: 'Arial';
    font-size: 18px;
    line-height: 23px;
    color: #393939;
    font-weight: 500;
    margin: 0 0 22px;
  }

  input[type='text'],
  input[type='password'],
  select,
  input[type='email'],
  input[type='url'],
  input[type='number'],
  textarea,
  input[type='tel'] {
    border: 1px solid #ececec;
    background-color: #fff;
    font-size: 14px;
    line-height: 20px;
    color: #575757;
    height: 40px;
    margin-bottom: 20px;
    width: 100%;
    padding: 0px 15px;
    border-radius: 0;
  }

  textarea {
    padding: 10px;
    height: 167px;
  }

  input[type='submit'] {
    color: #fff;
    background-color: #33458d;
    height: 55px;
    padding: 0px 65px;
    font-size: 12px;
    line-height: 20px;
    border: 2px solid #33458d;
    border-radius: 0;
    text-transform: uppercase;
    margin-bottom: 20px;
    font-weight: 600;
  }

  input[type='submit']:hover {
    transition: all 0.5s ease;
    -webkit-transition: all 0.5s ease;
    -moz-transition: all 0.5s ease;
    background-color: #fff;
    color: #33458d;
    border-color: #33458d;
  }

  select {
    //background: #fff url('../images/arrow.png') no-repeat right;
    padding: 0 40px 0 30px;
  }

  input[type='checkbox'],
  input[type='radio'] {
    margin: 0 10px 20px 0;
  }

  ::-webkit-input-placeholder {
    color: #575757;
  }

  ::-moz-placeholder {
    color: #575757;
    opacity: 1;
  }

  :-ms-input-placeholder {
    color: #575757;
  }

  :-moz-placeholder {
    color: #575757;
    opacity: 1;
  }

  .main_table h1 {
    font-family: 'Arial';
    font-size: 20px;
    line-height: 29px;
    color: #000;
    font-weight: 600;
    margin: 0 0 17px;
    text-align: center;
  }

  .main_table h2 {
    font-family: 'Arial';
    font-size: 15px;
    line-height: 20px;
    color: #000000;
    font-weight: 800;
    margin: 0 0 0;
  }

  .main_table h3 {
    font-family: 'Arial';
    font-size: 16px;
    line-height: 20px;
    color: #000000;
    font-weight: 800;
    margin: 0 0 0px;
  }

  .first_border {
    border: 1px solid #000000;
  }

  .form_1 p small {
    /*border-bottom: 2px dotted #969696;
    width: 100%;*/
    border-bottom: 2px dotted #969696;
    width: 85%;
    display: table;
    float: right;
  }

  .form_1 {
    border-right: 1px solid #000000;
  }

  .form_1 {
    border-right: 1px solid #000000;
    /*padding: 10px 10px;*/
    padding: 10px 10px 0 10px;
  }

  small.big_b.v {
    border: 0;
  }

  .main_table {
    margin: 0 auto;
    size: 7in 9.25in;
    margin: 27mm 16mm 27mm 16mm;
  }

  /* change the margins as you want them to be. */
  .form_1.brr {
    border: 0 !important;
    /*padding: 6px 0;*/
    padding: 6px 10px 6px 0;
  }

  .main_table h4 {
    font-family: 'Arial';
    font-size: 16px;
    line-height: 23px;
    color: #000000;
    font-weight: 800;
    margin: 0 0 10px;
    width: 100%;
  }

  .main_table h3 {
    font-family: 'Arial';
    /*font-size: 18px;*/
    line-height: 23px;
    color: #000000;
    font-weight: 800;
    margin: 0 0 10px;
    width: 100%;
  }

  .main_table p {
    font-weight: 500;
    font-size: 15px;
    color: #000000;
    margin: 3px 0;
    line-height: 20px;
  }

  .first_border.six .form_1 h4 span {
    color: #000000;
    font-size: 14px;
    font-weight: 100;
  }

  .first_border.six .form_1 h3 span {
    color: #000000;
    font-size: 14px;
    font-weight: 100;
  }

  .first_border.seven h2 {
    font-family: 'Arial';
    font-size: 15px;
    line-height: 20px;
    color: #000000;
    font-weight: 500;
    margin: 0;
  }

  .date_main {
    margin: 0 auto;
    display: block;
    width: 100%;
    padding: 0 10px 0 !important;
  }

  .form_1.form_2 {
    min-height: 236px;
    padding-left: 7px;
    margin-left: -15px;
  }

  .first_border.ex .form_1 {
    min-height: 170px;
  }

  .last_text ul {
    margin: 10px 0 0;
  }

  .last_text ul li {
    font-size: 12px;
    color: #000;
    font-weight: 500;
    line-height: 18px;
    list-style: none;
  }

  .first_border.second .form_1 {
    border-right: 1px solid #000000;
    padding: 10px 10px;
    min-height: 50px;
  }

  .table > thead > tr > th {
    vertical-align: bottom;
    border-bottom: 2px solid #000;
  }

  table {
    width: 100% !important;
  }

  table, th, td {
    border: 1px solid black !important;
    border-collapse: collapse !important;
    margin-bottom: -1px !important;
  }

  .table-bordered {
    border: 1px solid #000;
  }

  .first_border.sixx {
    padding: 0px 10px;
  }

  small.big_b {
    position: relative;
  }

  small.big_b:after {
    content: '';
    position: absolute;
    bottom: -2px;
    left: 0;
    width: 190px;
    height: 2px;
    border-bottom: 2px dotted #cccccc;
  }

  small.big_b.s:after {
    content: '';
    position: absolute;
    bottom: -2px;
    left: 0;
    width: 150px;
    height: 2px;
    border-bottom: 2px dotted #cccccc;
  }

  //.first_border.sixx input[type='checkbox'] {
  //  float: right;
  //  margin: 0 70px 0 0px;
  //}

  .datediv {
    margin: 0 0 10px;
  }

  /*.first_border .form_1 p small {
      position: relative;
  }.first_border .form_1 p small:after {
      content: '';
      position: absolute;
      bottom: -2px;
      left: 0;
      width: 150px;
      height: 2px;
      border-bottom: 2px dotted #cccccc;
  }*/

  small.big_b.v:after {
    width: 110px;
    left: 7px;
  }

  small.big_b.x:after {
    width: 110px;
  }

  small.big_b.x:after {
    width: 290px;
  }

  small.big_b.v.v:after {
    width: 310px;
    left: 7px;
  }

  small.big_b.g:after {
    bottom: -11px;
    left: 0;
    width: 62px;
  }

  small.big_b.v.b:after {
    width: 190px;
    left: 0;
    bottom: 0;
    /*left: 7px;*/
    /*bottom: 4px;*/
  }

  small.big_b.v.c:after {
    width: 100px;
    left: 7px;
  }

  small.big_b.v.c.c:after {
    /*width: 110px;
    left: 7px;*/
    width: 110px;
    left: 61px;
  }

  small.big_b.v.c.c123:after {
    width: 210px;
    left: 5px;
    top: 16px;
  }

  small.big_b.z:after {
    bottom: -2px;
    left: 0;
    width: 460px;
    border-bottom: 2px dotted #cccccc00;
  }

  small.big_b.z:before {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 495px;
    height: 2px;
    border-bottom: 2px dotted #cccccc;
  }

  small.big_b.z {
    border: 0;
  }

  /* The container */
  .containerd {
    display: inline-block;
    position: relative;
    padding-left: 35px;
    margin-bottom: 18px;
    cursor: pointer;
    font-size: 22px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }

  /* Hide the browser's default checkbox */
  .containerd input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }

  .empty-box {
    height: 20px;
    width: 20px;
    background-color: #eee;
    border: 2px solid #000000;
    margin: 0mm !important
  }

  /* Create a custom checkbox */

  .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 20px;
    width: 20px;
    background-color: #eee;
    border: 2px solid #000000;
  }

  /* On mouse-over, add a grey background color */
  .containerd:hover input ~ .checkmark {
    background-color: #ccc;
  }

  /* When the checkbox is checked, add a blue background */
  .containerd input:checked ~ .checkmark {
    background-color: #000000;
  }

  /* Create the checkmark/indicator (hidden when not checked) */
  .checkmark:after {
    content: '';
    position: absolute;
    display: none;
  }

  /* Show the checkmark when checked */
  .containerd input:checked ~ .checkmark:after {
    display: block;
  }

  /* Style the checkmark/indicator */
  .containerd .checkmark:after {
    left: 6px;
    top: 1px;
    width: 5px;
    height: 10px;
    border: solid white;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }

  .sixx .containerd {
    float: right;
    margin-right: 27.78125mm;
  }

  .ex .main_table h3 span {
    margin-left: 30px;
    margin-bottom: 4px;
    display: inline-block;
  }

  .table-bordered > tbody > tr > td,
  .table-bordered > tbody > tr > th,
  .table-bordered > tfoot > tr > td,
  .table-bordered > tfoot > tr > th,
  .table-bordered > thead > tr > td,
  .table-bordered > thead > tr > th {
    border: 1px solid #000;
  }

  .pd0 {
    padding-left: 0;
  }

  small.big_b.t:after {
    content: '';
    position: absolute;
    bottom: -14px;
    left: -30px;
    width: 170px;
    height: 2px;
    border-bottom: 2px dotted #cccccc;
  }

  small.big_b.three:after {
    content: '';
    position: absolute;
    bottom: 4px;
    left: 4px;
    width: 230px;
    height: 2px;
    border-bottom: 2px dotted #cccccc;
  }

  .first_border.five .table {
    margin-bottom: 0;
    border-bottom: 0 !important;
  }

  .first_border.five table.table.table-bordered {
    border-bottom: 0;
    border-right: 0;
    border-left: 0 !important;
  }

  .first_border.five .form_1 {
    border-right: 1px solid #000000;
    padding: 10px 0px 0 !important;
  }

  .first_border.five h3 {
    margin: 0 10px 0px !important;
  }

  table.table.custom tr td {
    border: 0;
  }

  table.table.custom {
    border: 0;
  }

  table.table.custom tr td {
    border: 0;
    border-left: 1px solid #000;
    border-right: 1px solid #000;
    padding: 12px 8px;
  }

  table.table.custom > tbody > tr > td,
  table.table.custom > tbody > tr > th,
  table.table.custom > tfoot > tr > td,
  table.table.custom > tfoot > tr > th,
  table.table.custom > thead > tr > td,
  table.table.custom > thead > tr > th {
    padding: 18px 9px;
  }

  td.ppp {
    padding: 0 !important;
  }

  .form_1 table tr th {
    text-align: center;
  }

  small.big_b.u:after {
    content: '';
    position: absolute;
    bottom: -2px;
    left: 10px;
    width: 160px;
    height: 2px;
    border-bottom: 2px dotted #cccccc;
  }

  p.pull-right.r {
    margin-right: 158px;
  }

  .linee {
    position: relative;
  }

  .linee:after {
    position: absolute;
    content: '';
    top: 100px;
    left: 0px;
    width: 100%;
    height: 2px;
    border-bottom: 1px solid #000000;
  }

  .linee .form_1.brr.three {
    margin: 5px 0 0;
  }

  .form_1.brr h4 span {
    font-size: 16px;
    font-weight: 100;
    display: inline-block;
    margin: 0 0px 0 50px;
  }

  .sixx .containerd {
    float: right;
    margin-right: 27.78125mm;
  }

  .sixx .containerd span.checkmark {
    margin: -20px 0px 0 20px !important;
  }

  h4.bz span {
    margin: 0 0 0 2cm !important;
  }

  .box_5 .form_1 p small {
    border-bottom: 0px dotted #969696;
    width: 100%;
  }

  .box_5 .form_12 p small {
    //width: unset !important;
  }

  .box_5 .form_1 p {
    position: relative;
    margin: 2px 0;
  }

  .box_5 .form_1 p:after {
    position: absolute;
    content: '';
    left: 46px;
    width: 85%;
    height: 2px;
    border-bottom: 2px dotted #cccccc;
    bottom: 2px;
  }

  .box_5 .form_1 p.f12321:after {
    position: absolute;
    content: '';
    left: 88px;
    width: 70%;
    height: 2px;
    border-bottom: 2px dotted #cccccc;
    bottom: 2px;
  }

  .box_5 .form_1 p.f123212:after {
    position: absolute;
    content: '';
    left: 114px;
    width: 61%;
    height: 2px;
    border-bottom: 2px dotted #cccccc;
    bottom: 2px;
  }

  .box_5 .form_1 p.f123213:after {
    position: absolute;
    content: '';
    left: 84px;
    width: 71%;
    height: 2px;
    border-bottom: 2px dotted #cccccc;
    bottom: 2px;
  }

  .box_5 .form_1 p.f1232134:after {
    position: absolute;
    content: '';
    left: 104px;
    width: 65%;
    height: 2px;
    border-bottom: 2px dotted #cccccc;
    bottom: 2px;
  }

  .box_5 .form_1 p.f1232133:after {
    position: absolute;
    content: '';
    left: 65px;
    width: 78%;
    height: 2px;
    border-bottom: 2px dotted #cccccc;
    bottom: 2px;
  }

  small.big_b321.v.v:after {
    width: 350px;
    left: 0px;
  }

  .w-70 {
    width: 70% !important;
  }

  .w-43 {
    width: 43% !important;
  }

  .w-73 {
    width: 73% !important;
  }


  .w-63 {
    width: 63% !important;
  }

  small.big_b999.v.v:after {
    width: 203px;
    left: 0;
  }

  small.big_b9999.v.v:after {
    width: 300px;
    left: 0;
  }

  small.big_b32132165489.v.v:after {
    width: 313px;
    left: 23px;
  }

  small.big_b321654.three:after {
    left: 36px;
  }

  small.big_bvf231:after {
    width: 156px !important;
    left: 10px !important;
  }

  small.big_b88988998:after {
    width: 123px !important;
    left: 61px !important;
  }

  small.big_b88988998fff:after {
    width: 184px !important;
    left: 0 !important;
  }

  .box_5 .form_2 p.f1232133:after {
    position: absolute;
    content: '';
    left: 65px;
    width: 79%;
    height: 2px;
    border-bottom: 2px dotted #cccccc;
    bottom: 2px;
  }

  .box_5 .form_2 p.f1232134:after {
    position: absolute;
    content: '';
    left: 104px;
    width: 67%;
    height: 2px;
    border-bottom: 2px dotted #cccccc;
    bottom: 2px;
  }

  .box_5 .form_2 p.f12321:after {
    position: absolute;
    content: '';
    left: 88px;
    width: 72%;
    height: 2px;
    border-bottom: 2px dotted #cccccc;
    bottom: 2px;
  }

  .box_5 .form_2 p.f123212:after {
    position: absolute;
    content: '';
    left: 114px;
    width: 64%;
    height: 2px;
    border-bottom: 2px dotted #cccccc;
    bottom: 2px;
  }

  .box_5 .form_2 p.f123213:after {
    position: absolute;
    content: '';
    left: 84px;
    width: 73%;
    height: 2px;
    border-bottom: 2px dotted #cccccc;
    bottom: 2px;
  }

  .row {
    width: 100%;
    margin: 0 auto;
  }
  .container {
    width: 1170px;
  }

  .w-50 {
    width: 50% !important;
    padding: 0px !important;
    margin: 0px !important;
  }
  .w-33 {
    width: 33.3% !important;
    padding: 0px !important;
    margin: 0px !important;
  }
  .w-100 {
    width: 100% !important;
    padding: 0px !important;
    margin: 0px !important;
  }

  .pd-14 {
    padding-left: 14px !important;
  }

  .first_border {
    margin-top: -1px
  }

  .form {
    margin-right: -1px;
  }

  .ml-50 {
    margin-left: 50% !important;
  }

  .m-left-45 {
    margin-left: 4.5cm !important;
  }

  .ml-2cm {
    margin-left: 2cm !important;
  }
  .w-45 {
    width: 45%;
  }

  .w-55 {
    width: 55%;
  }

  .line-order {
    display: inline-block !important;
    margin-left: 8.8cm !important;
  }

  .m3 {
    font-size: 16px;
    margin-left: 9.2cm;
    margin-top: 14px;
  }

  .fake-table {
    display: none;
  }

  .tonne {
    font-size: 16px;
  }

  .margin-top-14 {
    margin-top: 14px
  }

  .letter-spacing {
    letter-spacing: 1px;
    color: #cccccc
  }

  .sign {
    width: 150px;
    margin-left: 30px;
    margin-top: -30px;
  }
}
