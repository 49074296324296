.button {
  border: 1px solid transparent;
  border-radius: $border-radius;
  text-align: center;
  white-space: nowrap;
  padding: calc(0.375em - 1px) 0.75em;
  background: none;
  height: 2.25em;
  line-height: 1.5;

  &:not([disabled]) {
    cursor: pointer;
  }

  &.is- {
    &primary {
      background: $primary-color;
      color: #fff;

      &:hover {
        background: darken($primary-color, 5%);
      }
    }

    &danger {
      background: $error-color;
      color: #fff;

      &:hover {
        background: darken($error-color, 5%);
      }
    }

    &primary-outline {
      border-color: $primary-color;

      &:hover {
        border-color: darken($primary-color, 5%);
      }
    }

    &text {
      padding: 0;
      text-transform: none;

      &.is-danger {
        color: $error-color;

        &:hover {
          color: darken($error-color, 5%);
        }
      }
    }
  }
}
