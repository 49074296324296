.login-container {
  background-image: linear-gradient(#000d20bd, #3a3e66c7), url("../../img/auth-background.png");
  height: 100%;
  width: 100%;
  background-repeat: no-repeat;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .fields-container {
    height: 260px;
    width: 420px;
    border-radius: 2px;
    border: 1px solid #FFFFFF;
    background-color: #FFFFFF;
    padding-left: 5%;
    padding-right: 5%;

  }
  .text-center {
    text-align: center;
  }

  .logo {
    width: 200px;
    margin-bottom: 52px;
  }
}
